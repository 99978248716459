import React, {useState} from "react";
import {Form, Button, Container, Alert} from 'react-bootstrap';
import axios from 'axios';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const [notification, setNotification] = useState({
      message: '',
      type: 'success',
      visible: false
    })

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post('https://emanuelalcala.com/api/contact/', formData)
            .then(response => {
                console.log('Message sent successfully: ', response);
                // Display message sent successfully
                setNotification({
                  message: "Message sent successfully!",
                  type: 'success',
                  visible: true
                })
                // Clear form data
                setFormData({
                  name: '',
                  email: '',
                  message: ''
                })
            })
            .catch(e => {
                console.error('There was an error sending message: ', e);
                // Display error in sending message
                setNotification({
                  message: 'Message sent unsuccessfully.',
                  type: 'danger',
                  visible: true
                })
            });
    }

    return (
    <Container className="fullscreen d-flex flex-column justify-content-center custom-primary-text w-50">
      <h2>Contact Me</h2>
      {notification.visible && (
        <Alert variant={notification.type} onClose={() => setNotification({...notification, visible: false})} dismissible>
          {notification.message}
        </Alert>
      )}
      <Form onSubmit={handleSubmit}>
        <Form.Group className="" controlId="formName">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formMessage">
          <Form.Label>Message</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Button className="mt-3" variant="primary" type="submit">
          Send
        </Button>
      </Form>
    </Container>
  );
};

export default ContactForm;