import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css'
import ProjectList from './components/ProjectList';
import About from './components/About';
import ContactForm from './components/ContactForm';
import NavBar from './components/Navbar';

function App() {
  return (
    <div className="custom-bg-dark skranji-regular">
      <NavBar />
      <div id='home-section' className='mt-5 pt-5 delete-lr-margin'>
        <About/>
      </div>

      <div id="project-section" className='delete-lr-margin'>
        <ProjectList />
      </div>

      <div id="contact-section" className='container-fluid'>
        <ContactForm />
      </div>
      
    </div>
      
  );
}

export default App;
