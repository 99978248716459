import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Container, Button, Card } from 'react-bootstrap';

function ProjectList() {
    const [ projects, setProjects ] = useState([])
    const [currentProject, setCurrentProject] = useState(0)

    useEffect(() => {
        axios.get('https://emanuelalcala.com/api/projects/')
            .then(response => setProjects(response.data))
            .catch(e => console.error(e));
    }, []);

    const handleNext = () => {
        {/* We check if 0 is returned with % projects.length so that the 
            index goes back to the first project again after reaching th end. */}
        setCurrentProject((prev) => (prev + 1) % projects.length);
    }

    const handlePrev = () => {
        setCurrentProject((prev) => (prev - 1 + projects.length) % projects.length);
    }

    return (
        <Container fluid className="fullscreen custom-bg-dark-gradient2 d-flex flex-column justify-content-center align-items-center p-3 p-md-5">
            {projects.length > 0 &&
                <div className="project-select p-3 p-md-4 d-flex flex-column flex-md-row align-items-center mt-5 mt-md-5">
                    <Button className='me-3 mb-3 mb-md-0' variant='light' onClick={handlePrev}>Prev</Button>
                    <div className='project-card-container w-100'> 
                        <Card key={projects[currentProject].id} border="30%" className="project-card custom-bg-card custom-primary-text p-3 mx-auto">
                            <Card.Img className="project-image mx-auto" variant='top' src={projects[currentProject].image} />
                            <Card.Title className='project-body mt-3'>{projects[currentProject].name}</Card.Title>
                            <Card.Subtitle className='project-body custom-accent-muted-text'>{projects[currentProject].tech_stack}</Card.Subtitle>
                            <Card.Body className='p-0 mt-2 project-body'>
                                {projects[currentProject].description}
                            </Card.Body>
                        </Card>
                    </div>
                    <Button className="ms-3 mt-3 mt-md-0" variant='light' onClick={handleNext}>Next</Button>
                </div>
            }
            <div className='d-flex justify-content-center align-items-center mt-1'>
                <a href="#contact-section">
                    <i className="social-link bi bi-chevron-double-down custom-primary-text"></i>
                </a>
            </div>
        </Container>
    );
}

export default ProjectList;