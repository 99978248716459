import { Container, Row, Card } from "react-bootstrap"

function About() {
    return (
        <Container fluid="True" className="fullscreen custom-bg-dark-gradient d-flex flex-column justify-content-center align-items-center pt-5 pt-md-0">
            <div className="d-flex flex-column flex-md-row align-items-md-center mt-md-5 mt-5 pt-5 pt-md-0">
                <div className="text-center text-md-start mb-4 mb-md-0">
                    <h3 className="custom-accent-text font-size-xl">Hello, I am a</h3>
                    <h1 className="custom-primary-text font-size-xxl">Software Developer</h1>
                    <h5 className="custom-primary-text">Passionate about learning, software development, and trying new foods.</h5>
                </div>
                <div className="ms-md-5">
                    <Card border="50%" className="custom-bg-card custom-primary-text p-3">
                        <Card.Body>
                            <p className="custom-accent-text">Languages:</p>
                            <p>Python, Java, Dart, JavaScript, HTML5&CSS, MySQL/SQLite</p>
                            <p className="custom-accent-text">Libraries/Frameworks:</p>
                            <p>React, Django, Flask, Spring Boot, Bootstrap/TailwindCSS, Flutter</p>
                            <p className="custom-accent-text">Additional Skills:</p>
                            <p>ProxMox/ESXi, Server Configuration, Network Switch Configuration, Git, Godot, VSCode, IntelliJ IDEA, Android Studio</p>
                        </Card.Body>
                    </Card>
                </div>
            </div>

            <div className="d-flex justify-content-center justify-content-md-start mt-5 w-100">
                <div className="text-center text-md-start">
                    <a href="https://www.linkedin.com/in/emanuel-alcala-arredondo/" target="_blank" rel="noopener noreferrer">
                        <i className="bi bi-linkedin social-link custom-primary-text"></i>
                    </a>
                </div>
                <div className="ms-3 text-center text-md-start">
                    <a href="https://github.com/Nuell-A" target="_blank" rel="noopener noreferrer">
                        <i className="bi bi-github social-link custom-primary-text"></i>
                    </a>
                </div>
            </div>

            <div className='d-flex justify-content-center align-items-center mt-5'>
                <a href="#project-section">
                        <i className="arrow bi bi-chevron-double-down custom-primary-text"></i>
                </a>
            </div>
        </Container>
    )
}

export default About;