import { Navbar, Nav, Container } from "react-bootstrap";

function NavBar() {
    return (
        <Navbar className="custom-bg-dark mb-0 pb-0 navbar-dim" variant="dark" expand="lg" fixed="top">
            <Container>
                <a href="#">
                    <img className="prf-pic me-3" src="/profesional-pic_scaled.jpg"></img>
                </a>
                <Navbar.Brand href="#">Emanuel Alcala-Arredondo</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav ms-5">
                    <Nav className="ms-auto pe-3">
                        <Nav.Link className="nav-item" href="#home-section">home</Nav.Link>
                        <Nav.Link className="nav-item" href="#project-section">projects</Nav.Link>
                        <Nav.Link className="nav-item" href="#contact-section">contact</Nav.Link> 
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavBar;